<template>
  <div style="width: 70vw; height: calc(100vh - 65px);">
    <!-- Alternative version for screen readers -->
    <div class="d-sr-only">
      <h2>Number of projects per country</h2>
      <ul>
        <li :key="key" v-for="[key, value] of Object.entries(projectsPerCountry)">
          Country: {{ key }}. {{ value.length }} projects.
          <ul>
            <li :key="project.id" v-for="project in value">
              Project: {{ project.name }}
              <ul>
                <li :key="project.id + 'categories'">
                  Categories: {{ project.labels.join(',') }}
                </li>
                <li :key="project.id + 'url'">
                  <v-btn
                    color="primary"
                    :aria-label="'Open project ' + project.name"
                    block
                    @click="openProject(project.index.toString())"
                  >
                    Open project
                  </v-btn>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
    </div>

    <l-map
      ref="map"
      v-resize="onResize"
      :aria-hidden="true"
      style="z-index: 0; width: 100%; height: 100%;"
      :zoom="zoom"
      :center="center"
      :options="mapOptions"
      @update:center="centerUpdate"
      @update:zoom="zoomUpdate"
      @ready="setBounds"
    >
      <l-tile-layer :visible="true" :url="url" :attribution="attribution" />
      <l-control-attribution position="bottomleft"></l-control-attribution>
      <v-marker-cluster ref="cluster" :average-center="true" :ignore-hidden="true">
        <l-marker
          :key="marker.index"
          :options="{ keyboard: true }"
          role="tooltip"
          :lat-lng="marker.coords"
          v-for="marker in markers"
        >
          <l-icon :icon-size="[32, 32]" icon-url="marker.png" />
          <l-popup>
            <p style="font-size: 14px">
              <span><v-icon class="pr-1">mdi-information</v-icon></span>
              {{ marker.name }}
            </p>
            <v-btn color="primary" block @click="openProject(marker.index.toString())">
              Open project
            </v-btn>
          </l-popup>
        </l-marker>
      </v-marker-cluster>
    </l-map>
  </div>
</template>

<script>
import { latLng } from 'leaflet'
import {
  LControlAttribution,
  LIcon,
  LMap,
  LMarker,
  LPopup,
  LTileLayer
} from 'vue2-leaflet'
import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ToolMap',
  components: {
    LIcon,
    LMap,
    LTileLayer,
    LMarker,
    LControlAttribution,
    'l-popup': LPopup,
    'v-marker-cluster': Vue2LeafletMarkerCluster
  },
  props: {
    country: {
      validator: prop => typeof prop === 'string' || prop === null,
      default: null,
      required: false
    },
    labels: {
      validator: prop => typeof prop === 'string' || prop === null,
      default: null,
      required: false
    }
  },
  data() {
    return {
      center: latLng(47.41322, 18.219482),
      url: 'https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png',
      attribution:
        '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
      zoom: 4.2,
      mapOptions: { attributionControl: false, minZoom: 4.2, keyboard: true }
    }
  },

  computed: {
    ...mapGetters('map', ['sortedResources']),
    projectsPerCountry() {
      return this.markers.reduce(function (res, item) {
        let country = item.countries && item.countries[0]
        if (!(country in res)) {
          res[country] = [item]
        } else {
          res[country].push(item)
        }
        return res
      }, {})
    },
    markers() {
      let m = this.sortedResources
        .map(item => this.prepareProject(item))
        .filter(project => project.coords)
      if (this.country) {
        m = m.filter(project => project.countries.includes(this.country))
      }
      if (this.labels) {
        let labelList = this.labels.split(',')
        m = m.filter(project => labelList.some(r => project.labels.includes(r)))
      }
      return m
    }
  },
  async mounted() {
    await this.list()
  },

  methods: {
    ...mapActions('map', ['list']),
    openProject(id) {
      let route = this.$router.resolve({
        name: 'project-detail',
        params: { id, edit: false }
      })
      window.open(route.href, '_blank')
    },
    setBounds() {
      /* console.log(this.$refs.map)
       * let map = this.$refs.map.leafletObject
       * console.log(map)
       * console.log('Bounds:', map.getBounds()) */
    },
    prepareProject(item) {
      let geo = item.geolocation
      if (geo) {
        let latlngStr = geo.split(',')
        let lat = parseFloat(latlngStr[0])
        let lng = parseFloat(latlngStr[1])
        return {
          index: item.id,
          name: item.name,
          coords: latLng(lat, lng),
          countries: item.countries,
          labels: item.labels
        }
      }
      return { index: item.id, coords: null }
    },
    onResize() {
      this.$refs.map.mapObject._onResize()
    },
    zoomUpdate(zoom) {
      this.zoom = zoom
    },
    centerUpdate(center) {
      this.center = center
    }
  }
}
</script>
<style>
@import '~leaflet.markercluster/dist/MarkerCluster.css';
@import '~leaflet.markercluster/dist/MarkerCluster.Default.css';
</style>
<style lang="scss">
.cluster {
  position: absolute;
  margin-top: -20px;
  margin-left: -20px;
}

.leaflet-bar a {
  color: #000 !important;
}

.leaflet-control-attribution a {
  color: #000 !important;
}

.leaflet-tooltip {
  width: 300px;
  white-space: normal;
}

.leaflet-tooltip p {
  width: 300px;
  padding: 10px;
  padding-right: 20px;
  padding-left: 20px;
  font-weight: 700;
  font-size: 15px;
  white-space: normal;
}
</style>
