<template>
  <v-card>
    <v-container fluid>
      <!-- Project Logo and Title -->
      <v-row no-gutters align="center">
        <!-- Project Logo -->
        <v-col class="pl-4" cols="4" justify="center" align="center">
          <project-logo
            :id="id"
            :category="project.labels[0]"
            :logo="logo"
            :country="country"
          />
        </v-col>
        <v-col cols="8">
          <v-card-title>
            <!-- If we don't use a div, some properties won't work -->
            <div :class="show ? 'max-lines-40' : 'max-lines-5'">
              <h2 class="project-title">{{ project.name }}</h2>
            </div>
          </v-card-title>
        </v-col>
      </v-row>
      <!-- - Project Categories -->
      <v-row no-gutters align="center" class="pl-3 pr-3 mt-4" v-if="!intranet">
        <v-col :key="label" class="pl-1" v-for="label in project.labels">
          <project-category :key="label" :category="label" />
        </v-col>
      </v-row>
      <!-- Project Description  -->
      <v-row no-gutters align="center">
        <v-col cols="12">
          <v-card-subtitle class="project-description">
            <div :class="show && intranet ? 'max-lines-40' : 'max-lines-6'">
              {{ project.description }}
            </div>
          </v-card-subtitle>
        </v-col>
      </v-row>
      <!-- Show more  -->
      <v-row class="mb-2 pl-4 pr-4 mt-0" v-if="intranet">
        <v-col cols="12" class="">
          <v-expand-transition>
            <div class="mb-2" v-show="show">
              <v-divider class="mb-4"></v-divider>
              <ul ref="expandedItems" class="no-bullets ml-2">
                <li
                  :key="index"
                  class="mt-1"
                  tabindex="0"
                  v-for="(item, index) in validAdditionalItems"
                >
                  <!-- Items with multiple values -->
                  <div v-if="item.multiple">
                    <div :key="text" class="mr-3" v-for="(text, i) in item.text">
                      <span>
                        <v-icon
                          class="mr-2"
                          alt="item.iconAlt"
                          v-text="item.icon"
                        ></v-icon>
                      </span>
                      <!-- prettier-ignore -->
                      <a class="item-url" target="_blank" :href="'//' + item.url[i]" v-if="item.url[i]">{{ text }}</a>
                      <span v-else>{{ text }}</span>
                    </div>
                  </div>
                  <!-- Single items  -->
                  <div v-else>
                    <span>
                      <v-icon class="mr-2" alt="item.iconAlt" v-text="item.icon"></v-icon>
                    </span>
                    <!-- prettier-ignore -->
                    <a class="item-url" target="_blank" :href="item.url" v-if="item.url != ''">{{ item.text }}</a>
                    <span v-else>{{ item.text }}</span>
                  </div>
                </li>
              </ul>
            </div>
          </v-expand-transition>
          <v-btn
            ref="expandButton"
            outlined
            small
            block
            :aria-label="
              (show ? 'Show less information ' : 'Show more information ') +
              ' about project ' +
              project.name
            "
            class=""
            @click="show = !show"
            v-if="intranet"
          >
            <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
            {{ show ? 'Hide' : 'Show more' }}
          </v-btn>
        </v-col>
      </v-row>
      <!-- Web and project view -->
      <v-row class="pl-4 mt-0 pr-4">
        <v-col cols="6" class="pa-0 pl-4">
          <v-btn
            :aria-label="'Web from project ' + project.name"
            block
            :href="project.url"
            target="_blank"
            small
            color="primary"
            class="project-website"
            v-if="project.url != ''"
          >
            <v-icon left>mdi-link-variant</v-icon>
            Web
          </v-btn>
        </v-col>
        <v-col cols="6" class="pt-0 pl-1 pr-4">
          <v-btn
            small
            block
            :aria-label="
              (intranet ? 'Edit project' : 'Project Details') + ': ' + project.name
            "
            color="primary"
            :to="{
              name: 'project-detail',
              params: { id: project.id.toString(), edit: intranet }
            }"
          >
            <v-icon small class="mr-2" v-if="intranet">mdi-pencil</v-icon>
            {{ intranet ? 'Edit project' : 'Project Details' }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-divider class="mt-3" />
    <v-card-actions class="pa-1">
      <slot name="actions"></slot>
    </v-card-actions>
  </v-card>
</template>

<script>
import ProjectCategory from '@/views/Project/ProjectCategory.vue'
import ProjectLogo from '@/views/Project/ProjectLogo.vue'

export default {
  name: 'ProjectCard',
  components: {
    ProjectCategory,
    ProjectLogo
  },
  props: {
    project: {
      type: Object,
      required: true
    },
    intranet: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      id: this.project.id,
      show: false
    }
  },
  computed: {
    logo() {
      return this.project.logo || ''
    },
    country() {
      return this.project.countries
    },
    additionalItems() {
      return [
        {
          text: this.project.company_name && this.project.company_name.split('|'),
          url: this.project.company_url && this.project.company_url.split('|'),
          icon: 'mdi-office-building',
          iconAlt: 'Project company icon',
          multiple: true
        },
        {
          text: this.project.countries.join(', '),
          icon: 'mdi-flag',
          iconAlt: 'Project countries icon'
        },
        {
          text: this.project.status
            ? 'Status: '.concat(this.project.status)
            : 'Unknown status',
          icon: 'mdi-list-status',
          iconAlt: 'Project status icon',
          url: ''
        },
        {
          text: this.project.budget
            ? this.project.budget.toString().concat(' ').concat(this.project.currency)
            : 'Unknown',
          icon: 'mdi-cash-multiple',
          iconAlt: 'Project budget icon',
          url: ''
        },
        {
          text:
            this.project.disabilities && this.project.disabilities.length
              ? this.project.disabilities
              : 'Unknown',
          icon: 'mdi-human',
          iconAly: 'Disablities',
          url: ''
        }
      ]
    },
    validAdditionalItems() {
      return this.additionalItems.filter(
        it => !it.text.toString().toLowerCase().includes('unknown')
      )
    }
  },
  watch: {
    show: function (val) {
      if (val) {
        this.$nextTick(function () {
          this.$refs.expandButton.$el.focus()
        })
      }
    }
  },
  created() {},
  methods: {}
}
</script>

<style lang="scss" scoped>
.max-lines-3 {
  display: -webkit-box;
  overflow: hidden;
  line-height: 2.5ex;
  white-space: normal;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.max-lines-6 {
  display: -webkit-box;
  height: 12.5ex;
  overflow: hidden;
  line-height: 2.5ex;
  white-space: normal;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 6;
}

.max-lines-5 {
  display: -webkit-box;
  overflow: hidden;
  line-height: 2.5ex;
  white-space: normal;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
}

.max-lines-40 {
  display: -webkit-box;
  overflow: hidden;
  line-height: 2.5ex;
  white-space: normal;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 40;
}

.project-title {
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  word-break: keep-all;
}

.project-description {
  font-size: 13px;
}

.project-website {
  height: 28px !important;
}

.item-url {
  font-size: 15px;
}

ul.no-bullets {
  margin: 0; /* Remove margins */
  padding: 0; /* Remove padding */
  list-style-type: none; /* Remove bullets */
}
</style>
