<template>
  <v-row dense>
    <v-col cols="12" dense>
      <v-row align="center" dense>
        <v-col cols="12" class="pl-1 pr-1 pt-2">
          <project-filter
            :value="telework"
            category="TELEWORK"
            @input="v => $emit('update:telework', v)"
          />
        </v-col>
        <v-col cols="12" class="pl-1 pr-1 pt-2">
          <project-filter
            :value="elearning"
            category="ELEARNING"
            @input="v => $emit('update:elearning', v)"
          />
        </v-col>
        <v-col cols="12" class="pl-1 pr-1 pt-2">
          <project-filter
            :value="telecare"
            category="TELECARE"
            @input="v => $emit('update:telecare', v)"
          />
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" class="mt-3 pt-2 pl-1 pr-1 pb-1">
      <dropdown-filter
        :value="country"
        country
        publicview
        @input="v => $emit('update:country', v)"
      />
    </v-col>
    <v-col class="pa-1 pr-3 pt-2" cols="7">
      <v-btn block color="primary" outlined @click="search">
        <v-icon left>mdi-magnify</v-icon>
        Search projects
      </v-btn>
    </v-col>
    <v-col cols="5" class="pa-1 pt-2">
      <v-btn block color="secondary" @click="reset">
        <v-icon left>mdi-filter-off</v-icon>
        Reset filters
      </v-btn>
    </v-col>
    <v-col class="mt-3" cols="12"><v-divider /></v-col>
  </v-row>
</template>
<script>
import DropdownFilter from '@/components/Filters/DropdownFilter.vue'
import ProjectFilter from '@/components/Filters/ProjectFilter.vue'

export default {
  name: 'ToolFilterBar',
  components: { DropdownFilter, ProjectFilter },
  props: {
    telework: {
      type: Boolean,
      required: true
    },
    elearning: {
      type: Boolean,
      required: true
    },
    telecare: {
      type: Boolean,
      required: true
    },
    country: {
      validator: prop => typeof prop === 'string' || prop === null,
      required: true
    }
  },
  data() {
    return {}
  },
  methods: {
    search() {
      this.$emit('search')
    },
    reset() {
      this.$emit('reset')
    }
  }
}
</script>
