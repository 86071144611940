<template>
  <v-container fluid class="pa-0" fill-height>
    <v-row no-gutters fill-height>
      <v-col cols="12" md="7" class="project-info">
        <new-project-info :lang="lang" />
      </v-col>
      <v-divider vertical />
      <v-col cols="12" md="5">
        <new-project-form ref="projectForm" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'

import { STORAGE_TYPE } from '@/core/config'
import { addToStore, retrieveFromStore } from '@/core/helpers/storage'
import NewProjectForm from '@/views/New/NewProjectForm.vue'
import NewProjectInfo from '@/views/New/NewProjectInfo.vue'

export default {
  name: 'NewProject',
  components: {
    NewProjectInfo,
    NewProjectForm
  },
  data() {
    return {
      lang: 'en'
    }
  },
  computed: {
    queryLang() {
      return this.$route.query.lang
    }
  },
  async created() {
    if (this.queryLang) {
      await addToStore('lang', this.queryLang, STORAGE_TYPE.LOCALSTORAGE)
    }
    this.lang = (await retrieveFromStore('lang', STORAGE_TYPE.LOCALSTORAGE)) || 'en'
    this.setShowSideBar(true)
  },
  methods: {
    ...mapActions(['setShowSideBar', 'setShowAppBar'])
  }
}
</script>
