<template>
  <v-container class="ml-1 mr-1 pa-5" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12">
        <v-row>
          <v-col cols="8">
            <v-text-field v-model="filter.name" outlined label="Search project by title">
              <!-- Accessibility: We don't just use 'clearable' because we can't
                   change it aria-label
              -->
              <template #append>
                <v-btn
                  aria-label="Clear search text"
                  small
                  icon
                  @click="filter.name = null"
                  v-if="filter.name && filter.name.length > 0"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </template>
            </v-text-field>
          </v-col>
          <v-col col="4">
            <v-btn style="height: 50px" color="primary" block @click="search">
              <v-icon class="mr-2">mdi-card-search-outline</v-icon>
              Search
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <search-project-list :filters="queryFilter" @reject="reject" @publish="publish" />
      </v-col>
    </v-row>
    <v-row v-if="rejectDialog">
      <!-- Seme model in Intranet.vue -->
      <intranet-rejection-modal :value.sync="rejectDialog" @confirm="rejectConfirm" />
    </v-row>
  </v-container>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'

import IntranetRejectionModal from '@/views/Intranet/IntranetRejectionModal.vue'
import SearchProjectList from '@/views/Search/SearchProjectList.vue'

// Filter for public users
const INIT_FILTER_USERS = {
  name: '',
  review_status: 'PUBLISHED',
  hidden: false
}

// Filter for reviewers (logged users)
const INIT_FILTER_REV = {
  name: ''
}

export default {
  name: 'Search',
  components: { SearchProjectList, IntranetRejectionModal },
  data() {
    return {
      filter: this.isUserLoggedIn ? { ...INIT_FILTER_REV } : { ...INIT_FILTER_USERS },
      rejectDialog: false,
      rejectInfo: {},
      queryFilter: this.getQueryFilter(
        this.isUserLoggedIn ? { ...INIT_FILTER_REV } : { ...INIT_FILTER_USERS }
      )
    }
  },
  computed: {
    ...mapGetters('auth', ['isUserLoggedIn']),
    ...mapGetters('user', ['me'])
  },
  created() {
    this.setShowSideBar(true)
    this.setShowAppBar(true)
  },
  methods: {
    ...mapActions(['setShowSideBar', 'setShowAppBar']),
    ...mapActions('project', { projectUpdate: 'update' }),
    ...mapActions('review', { reviewUpdate: 'update' }),
    search: function () {
      this.queryFilter = this.getQueryFilter(this.filter)
    },
    getQueryFilter: function (filter) {
      if (this.isUserLoggedIn) {
        return {
          name: filter.name || 'sillywayofavoidingshowinganyproject'
        }
      } else {
        return {
          name: filter.name || 'sillywayofavoidingshowinganyproject',
          review_status: 'PUBLISHED',
          hidden: false
        }
      }
    },
    // Same function in Intranet.vue
    reject: function (project, labels) {
      this.rejectDialog = true
      this.rejectInfo = { project, labels }
    },
    // Same function in Intranet.vue
    rejectConfirm: async function (reason) {
      let labels = this.rejectInfo.labels
      let project = this.rejectInfo.project
      let reason_user = this.me.username + '||' + reason
      let review = { review_status: 'REJECTED', comment: reason_user }
      await this.projectUpdate({ id: project.id, labels })
      await this.reviewUpdate({ id: project.review.id, ...review })
      // Force refresh after update
      await this.search()
    },
    // Same function in Intranet.vue
    publish: async function (project, labels) {
      let review = { review_status: 'PUBLISHED', comment: '' }
      await this.projectUpdate({ id: project.id, labels })
      await this.reviewUpdate({ id: project.review.id, ...review })
      // Force refresh after update
      await this.search()
    }
  }
}
</script>
