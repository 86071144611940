<template>
  <v-img alt="Project Logo" height="100" :src="getLogoImage(logo)">
    <span class="project-country-on-image">
      <project-country :country="country" :dark="true" />
    </span>
  </v-img>
</template>

<script>
import ProjectCountry from '@/views/Project/ProjectCountry.vue'

export default {
  name: 'ProjectLogo',
  components: { ProjectCountry },
  props: {
    id: {
      type: Number,
      required: true
    },
    logo: {
      type: String,
      required: true
    },
    country: {
      type: Array,
      required: true
    },
    category: {
      type: String,
      required: true
    }
  },
  data() {
    return {}
  },
  methods: {
    getLogoImage(logo) {
      if (logo == '') {
        if (this.category == 'ELEARNING') {
          const num = this.id % 16
          logo = 'categories/ELEARNING/' + num + '.jpg'
        } else if (this.category == 'TELECARE') {
          const num = this.id % 15
          logo = 'categories/TELECARE/' + num + '.jpg'
        } else if (this.category == 'TELEWORK') {
          const num = this.id % 19
          logo = 'categories/TELEWORK/' + num + '.jpg'
        } else {
          logo = 'logo.png'
        }
      }
      if (logo.startsWith('http')) {
        return logo
      } else {
        return require(`@/assets/img/${logo}`)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.project-country-on-image {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  color: #fff;
  font-size: 14px;
  background-color: rgba($color-theme-light-primary, 0.8);
}
</style>
