<template>
  <bml-vuex-resource-iterator
    auto-refresh
    vuex-namespace="publicproject"
    hide-default-footer
    :cols-props="{ cols: 12 }"
    :external-filters="filters"
    :auto-refresh-interval="30000"
  >
    <template #header="{ loading, pagination, totalPages }">
      <div class="text-center mb-8">
        <v-row justify="center" align="center">
          <v-col cols="3" justify="cennter" align="center">
            <v-btn
              block
              outlined
              color="primary"
              @click="pagination.page = pagination.page - 1"
              v-if="totalPages > 1 && pagination.page != 1"
            >
              Previous
            </v-btn>
          </v-col>
          <v-col justify="cennter" align="center" cols="5">
            <span class="font-weight-bold">Page</span>
            <v-chip label class="ml-1 mr-1" color="primary">
              {{ pagination.page }}
            </v-chip>
            <span class="font-weight-bold">of {{ totalPages }}</span>
          </v-col>
          <v-col justify="cennter" align="center" cols="2">
            <v-btn
              outlined
              block
              color="primary"
              @click="pagination.page = pagination.page + 1"
              v-if="totalPages > 1 && pagination.page != totalPages"
            >
              Next
            </v-btn>
          </v-col>
        </v-row>
        <v-progress-linear
          color="primary"
          height="5"
          class="mb-5 mt-3"
          indeterminate
          v-if="loading && totalPages > 0"
        />
      </div>
    </template>

    <template #loading>
      <div style="height: 100px; text-align: center">
        <p>Loading. Please wait...</p>
      </div>
    </template>

    <template #item="{ item }">
      <project-card :project="item"></project-card>
    </template>
  </bml-vuex-resource-iterator>
</template>

<script>
import ProjectCard from '@/views/Project/ProjectCard.vue'

export default {
  name: 'ToolProjectList',
  components: { ProjectCard },
  props: {
    filters: {
      type: Object,
      required: true
    }
  },
  created() {},
  methods: {}
}
</script>
<style lang="scss">
.v-pagination__navigation .v-icon {
  color: var(--v-primary-base);
}
</style>
