<template>
  <bml-vuex-resource-iterator
    vuex-namespace="project"
    hide-default-footer
    :cols-props="{ cols: 12, md: 6, xl: 4 }"
    :external-filters="filters"
    v-show="filters.name != 'sillywayofavoidingshowinganyproject'"
  >
    <template #header="{ loading }">
      <v-progress-linear color="primary" class="mb-5" indeterminate v-if="loading" />
    </template>

    <template #loading>
      <div style="height: 100px; text-align: center">
        <p>Loading. Please wait...</p>
      </div>
    </template>

    <template #item="{ item }">
      <intranet-project-card
        :project="item"
        v-on="$listeners"
        v-if="!prLoading && isUserLoggedIn"
      ></intranet-project-card>
      <project-card
        :project="item"
        v-else-if="!prLoading && !isUserLoggedIn"
      ></project-card>
    </template>

    <template #footer="{ pagination, totalPages }">
      <div class="text-center mt-8 mb-8">
        <v-pagination
          v-model="pagination.page"
          :length="totalPages"
          :total-visible="7"
          circle
          v-if="totalPages > 1"
        ></v-pagination>
      </div>
    </template>
  </bml-vuex-resource-iterator>
</template>

<script>
import { mapGetters } from 'vuex'

import IntranetProjectCard from '@/views/Intranet/IntranetProjectCard.vue'
import ProjectCard from '@/views/Project/ProjectCard.vue'

export default {
  name: 'SearchProjectList',
  components: { IntranetProjectCard, ProjectCard },
  props: {
    filters: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      value: false
    }
  },
  computed: {
    ...mapGetters('auth', ['isUserLoggedIn']),
    ...mapGetters('project', { prLoading: 'loadingList' })
  },
  methods: {}
}
</script>
