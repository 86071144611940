<template>
  <v-container fluid fill-height>
    <v-row fill-height>
      <!-- Left part: List of projects -->
      <v-col class="scrollable-projects" cols="12" md="5" xl="4">
        <v-row>
          <v-col cols="12" class="pt-0 mt-0">
            <tool-filter-bar
              v-bind.sync="filter"
              @search="search"
              @reset="resetFilters"
            />
          </v-col>
          <v-col cols="12">
            <tool-project-list :filters="queryFilter" />
          </v-col>
        </v-row>
      </v-col>
      <!-- Right part: Map -->
      <v-col class="ma-0 pa-0" cols="12" md="7" xl="8">
        <tool-map :country="filterCountry" :labels="filterLabels" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import { STORAGE_TYPE } from '@/core/config'
import { addToStore } from '@/core/helpers/storage'
import ToolFilterBar from '@/views/Tool/ToolFilterBar.vue'
import ToolMap from '@/views/Tool/ToolMap.vue'
import ToolProjectList from '@/views/Tool/ToolProjectList.vue'

const INIT_FILTER = {
  telework: true,
  elearning: true,
  telecare: true,
  unknown: true,
  country: 'All countries'
}

export default {
  name: 'Tool',
  components: { ToolFilterBar, ToolMap, ToolProjectList },
  data() {
    return {
      filterCountry: null,
      filterLabels: null,
      filter: { ...INIT_FILTER },
      queryFilter: this.getQueryFilter(INIT_FILTER)
    }
  },
  computed: {
    ...mapGetters({ activeFiltersTool: 'activeFiltersTool' }),
    queryLang() {
      return this.$route.query.lang
    },
    labels: function () {
      let value = ''
      if (this.filter.telework) {
        value += 'TELEWORK,'
      }
      if (this.filter.telecare) {
        value += 'TELECARE,'
      }
      if (this.filter.elearning) {
        value += 'ELEARNING,'
      }
      return value.slice(0, -1)
    }
  },
  beforeDestroy() {
    this.setActiveFiltersTool({ ...this.filter })
  },

  async created() {
    if (this.queryLang) {
      await addToStore('lang', this.queryLang, STORAGE_TYPE.LOCASTORAGE)
    }
    if (this.activeFiltersTool) {
      this.filter = { ...this.activeFiltersTool }
    }
    this.queryFilter = this.getQueryFilter(this.filter)
    this.setShowSideBar(true)
    this.setShowAppBar(true)
  },
  methods: {
    ...mapActions(['setShowSideBar', 'setShowAppBar', 'setActiveFiltersTool']),
    getQueryFilter: function (filter) {
      let country = filter.country
      if (country == 'All countries') {
        country = null
      }
      this.filterCountry = country
      this.filterLabels = this.labels
      return {
        countries: country,
        review_status: 'PUBLISHED',
        labels: this.labels,
        // Show only projects with an associated review
        review_not_null: true
      }
    },
    search: function () {
      this.queryFilter = this.getQueryFilter(this.filter)
    },
    resetFilters: function () {
      this.filter = { ...INIT_FILTER }
    }
  }
}
</script>
<style lang="scss" scoped>
.scrollable-projects {
  height: 90vh;
  padding-top: 25px;
  padding-right: 30px;
  padding-left: 30px;
  overflow-y: auto;
}
</style>
