<template>
  <div style="width: 100%; height: 30vh">
    <l-map
      ref="map"
      v-resize="onResize"
      style="z-index: 0; width: 100%; height: 100%"
      :zoom="zoom"
      :center="coordinatesOrDefault"
      :options="mapOptions"
      @click="addMarker"
      @update:center="centerUpdate"
      @update:zoom="zoomUpdate"
    >
      <l-tile-layer :visible="true" :url="url" :attribution="attribution" />
      <l-marker
        :draggable="editMode"
        :lat-lng="coordinates"
        @update:lat-lng="updateCoordinates($event)"
        v-if="coordinates"
      ></l-marker>
      <l-control-attribution position="bottomleft"></l-control-attribution>
    </l-map>
  </div>
</template>

<script>
import { latLng } from 'leaflet'
import { LControlAttribution, LMap, LMarker, LTileLayer } from 'vue2-leaflet'

export default {
  name: 'ProjectMap',
  components: {
    LMap,
    LMarker,
    LTileLayer,
    LControlAttribution
  },
  props: {
    geolocation: {
      type: String,
      required: true
    },
    editMode: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      url: 'https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png',
      attribution:
        '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
      zoom: this.initialZoom(),
      mapOptions: { attributionControl: false, minZoom: 4 }
    }
  },
  computed: {
    coordinates() {
      return latLng(...this.geolocation.split(','))
    },
    coordinatesOrDefault() {
      return this.geolocation ? this.coordinates : latLng([45.843, 9.902])
    }
  },
  mounted() {},
  methods: {
    addMarker(event) {
      if (!this.coordinates) {
        this.updateCoordinates(event.latlng)
      }
    },
    initialZoom() {
      return this.geolocation ? 6.6 : 1
    },
    updateCoordinates(coords) {
      this.$emit('update:geolocation', coords.lat + ',' + coords.lng)
    },
    onResize() {
      this.$refs.map.mapObject._onResize()
    },
    zoomUpdate(zoom) {
      this.zoom = zoom
    },
    centerUpdate(center) {
      this.center = center
    }
  }
}
</script>
<style lang="scss">
.cluster {
  position: absolute;
  margin-top: -20px;
  margin-left: -20px;
}

.leaflet-bar a {
  color: #000 !important;
}

.leaflet-control-attribution a {
  color: #000 !important;
}
</style>
