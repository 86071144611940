<template>
  <div
    :style="'font-size: ' + flagSize + ' !important;'"
    class="project-country pa-1"
    v-if="countryText != ''"
  >
    <v-icon
      class="flag-icon-rounded"
      small
      :dark="dark"
      v-if="countries[countryText] == 'xx'"
    >
      mdi-earth
    </v-icon>
    <flag class="flag-icon-rounded" :iso="countries[countryText]" v-else />
    <span
      class="ml-1"
      :style="'font-size: ' + textSize + ' !important;  line-height: ' + flagSize"
    >
      {{ countryText }}
    </span>
    <span
      class="ml-1"
      :style="'font-size: ' + textSize + ' !important;  line-height: ' + flagSize"
      v-if="total > 1"
    >
      (+{{ total - 1 }})
    </span>
  </div>
</template>

<script>
import countries from '@/core/config/countries.js'

export default {
  name: 'ProjectCountry',
  props: {
    country: {
      type: Array,
      required: true
    },
    flagSize: {
      type: String,
      default: '16px'
    },
    textSize: {
      type: String,
      default: '12px'
    },
    dark: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      countries: Object.assign({}, ...countries.map(x => ({ [x.name]: x.flag })))
    }
  },
  computed: {
    countryText: function () {
      return this.country && this.country[0]
    },
    total: function () {
      return this.country.length
    }
  },
  methods: {}
}
</script>
<style lang="scss" scoped>
.project-country {
  span {
    vertical-align: middle;
  }
}
</style>
<style lang="scss">
.flag-icon-rounded {
  height: 1.2em;
  background-size: cover;
  border: 1px solid #fff;
  border-radius: 50%;

  &.flag-icon-squared {
    height: 1em;
  }
}
</style>
