<template>
  <div>
    <!-- Fullscreen loader overlay -->
    <div class="text-center" v-if="loading">
      <v-overlay :value="true" :opacity="0.3">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </div>
    <!-- Actual page contant -->
    <v-container pa-0 fluid v-else>
      <!-- Main row wth all project information (including additional) -->
      <v-row no-gutters>
        <!-- Main project information. Left side on big screens -->
        <v-col class="pa-0" cols="12" lg="8">
          <!-- Project image with title -->
          <v-col class="pa-0" cols="12">
            <v-img height="40vh" :src="shownLogo">
              <v-container
                fluid
                fill-height
                class="d-flex align-center img-container pa-6"
              >
                <v-row class="mb-8">
                  <v-col cols="12" lg="6">
                    <v-textarea
                      v-model="project.name"
                      auto-grow
                      outlined
                      rows="1"
                      :dark="true"
                      class="project-detail-title"
                      v-if="editMode"
                    />
                    <h1 class="project-detail-title" v-else>
                      {{ project.name }}
                    </h1>
                  </v-col>
                  <v-col cols="12" lg="6">
                    <v-file-input
                      v-model="newLogo"
                      label="Project logo"
                      accept="image/*"
                      truncate-length="90"
                      show-size
                      prepend-inner-icon="mdi-camera"
                      prepend-icon=""
                      :dark="true"
                      :rules="logoRules"
                      @change="previewImg"
                      v-if="editMode"
                    ></v-file-input>
                  </v-col>
                </v-row>
              </v-container>
            </v-img>
          </v-col>
          <!-- First reviewer-only parameters row -->
          <v-row
            justify="center"
            align="center"
            class="pa-1 pt-3 pb-3"
            v-if="isUserLoggedIn"
          >
            <!-- Back button -->
            <v-col class="pl-5 pr-5" justify="center" align="center" cols="6">
              <v-btn outlined block color="primary" @click="$router.go(-1)">
                <v-icon class="mr-2">mdi-arrow-left-thin-circle-outline</v-icon>
                Previous page
              </v-btn>
            </v-col>
            <v-col class="pl-5 pr-5" justify="center" align="center" cols="6">
              <v-btn
                :loading="!!loadingCreateOrUpdate"
                block
                color="primary"
                @click="clickSave"
              >
                <v-icon small class="mr-2">
                  {{ editMode ? 'mdi-content-save' : 'mdi-pencil' }}
                </v-icon>
                {{ editMode ? 'Save changes' : 'Edit project' }}
              </v-btn>
            </v-col>
          </v-row>
          <v-divider />
          <!-- Project public parameters row  -->
          <v-row justify="center" align="center" class="pa-1 pt-4 pb-4">
            <!-- Webpage  -->
            <v-col justify="center" align="center" cols="6" lg="4" class="pl-5 pr-5">
              <v-icon class="mr-2" v-if="!editMode">mdi-link</v-icon>
              <v-text-field
                v-model="project.url"
                style="height: 50px"
                outlined
                prepend-inner-icon="mdi-link"
                v-if="editMode"
              ></v-text-field>
              <a target="_blank" :href="project.url" v-else>Webpage</a>
            </v-col>
            <v-divider class="mt-2 mb-2" vertical style="border-right-width: 2px" />
            <!-- Creation date -->
            <v-col justify="center" align="center" cols="6" lg="4">
              <v-icon class="mr-2">mdi-calendar</v-icon>
              <span style="font-weight: 700">Included:</span>
              {{ createdAtFormat }}
            </v-col>
            <v-divider class="mt-2 mb-2" vertical style="border-right-width: 2px" />
            <!-- Project category -->
            <v-col class="pl-6 pr-6" justify="center" align="center" cols="6" lg="4">
              <v-select
                v-model="project.labels"
                style="height: 60px"
                :items="validCategories"
                label="Project Category*"
                multiple
                outlined
                required
                prepend-inner-icon="mdi-shape"
                :dark="false"
                v-if="editMode"
              ></v-select>
              <div v-else>
                <v-row>
                  <v-col
                    :key="label"
                    class="ma-0 pa-0 pr-3"
                    v-for="label in project.labels"
                  >
                    <project-category height="40px" :category="label" />
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </v-row>
          <v-divider />
          <!-- Second reviewer-only parameters row -->
          <v-row justify="center" align="center" class="pa-1" v-if="isUserLoggedIn">
            <v-divider class="mt-8 mb-8" vertical style="border-right-width: 2px" />
            <!-- Review status  -->
            <v-col justify="center" align="center" cols="6" lg="4">
              <v-icon class="mr-1">mdi-list-status</v-icon>
              Review:
              <span style="font-weight: 700">{{ publish }}</span>
            </v-col>
            <v-divider class="mt-4 mb-4" vertical style="border-right-width: 2px" />
            <!-- Source  -->
            <v-col justify="center" align="center" cols="6" lg="4">
              <v-icon class="mr-1">mdi-semantic-web</v-icon>
              Source:
              <span style="font-weight: 700">{{ source }}</span>
            </v-col>
            <v-divider class="mt-4 mb-4" vertical style="border-right-width: 2px" />
            <!-- Database id Database identifier-->
            <v-col justify="center" align="center" cols="6" lg="4">
              <v-icon class="mr-1">mdi-database</v-icon>
              DB Identifier:
              <span style="font-weight: 700">
                {{ project.id }}
              </span>
            </v-col>
          </v-row>
          <v-divider />
          <!-- Third reviewer-only parmeters row -->
          <v-row
            justify="center"
            align="center"
            class="pa-1 pt-2 pb-2"
            v-if="isUserLoggedIn"
          >
            <!-- Last update date -->
            <v-col justify="center" align="center" cols="6" lg="3">
              <v-icon class="mr-2">mdi-calendar</v-icon>
              <span style="font-weight: 700">Updated: :</span>
              {{ updatedAtFormat }}
            </v-col>
            <v-divider class="mt-4 mb-4" vertical style="border-right-width: 2px" />
            <!-- BigML Score -->
            <v-col justify="center" align="center" cols="6" lg="3">
              <div class="d-flex align-center justify-center">
                <v-img contain left max-height="40" max-width="40" :src="bigmlLogo" />
                <span class="ml-2">
                  BigML Score:
                  <span style="font-weight: 700">
                    {{ Math.trunc(project.review.order * 1000) / 1000 }}
                  </span>
                </span>
              </div>
            </v-col>
            <v-divider class="mt-4 mb-4" vertical style="border-right-width: 2px" />
            <!-- Contact email -->
            <v-col justify="center" align="center" cols="6" lg="3">
              <v-text-field
                v-model="project.contact_email"
                class="mb-3"
                style="height: 50px"
                prepend-inner-icon="mdi-mail"
                outlined
                label="Contact email"
                v-if="editMode"
              ></v-text-field>
              <div v-else>
                <v-icon class="mr-2">mdi-mail</v-icon>
                {{ project.contact_email || 'Unknown email' }}
              </div>
            </v-col>
            <v-divider class="mt-4 mb-4" vertical style="border-right-width: 2px" />
            <!-- Budget -->
            <v-col justify="center" align="center" cols="6" lg="3">
              <v-text-field
                v-model="project.budget"
                class="mb-3 pr-3"
                style="height: 50px"
                prepend-inner-icon="mdi-cash-multiple"
                outlined
                :label="'Budget (' + project.currency + ')'"
                v-if="editMode"
              ></v-text-field>
              <span v-else-if="project.budget">
                <v-icon class="mr-2">mdi-cash-multiple</v-icon>
                {{ project.budget.toString().concat(' ').concat(project.currency) }}
              </span>
              <span v-else>
                <v-icon class="mr-2">mdi-cash-multiple</v-icon>
                Unknown budget
              </span>
            </v-col>
          </v-row>
          <v-divider />
          <!-- Description row -->
          <v-row class="pa-8">
            <v-col cols="12">
              <v-textarea
                v-model="project.description"
                label="Description"
                outlined
                auto-grow
                rows="1"
                v-if="editMode"
              />
              <p v-else>{{ project.description }}</p>
            </v-col>
          </v-row>
        </v-col>
        <v-divider vertical />

        <!-- Additional information (right-side) on big screens -->
        <v-col cols="12" lg="4" class="pa-10 pt-1">
          <v-row>
            <v-col class="mt-6" cols="12">
              <h2>Additional information</h2>
            </v-col>
          </v-row>
          <v-divider class="mt-8 mb-8" />
          <!-- List of countries -->
          <v-row>
            <v-col cols="12">
              <h3>Countries</h3>
            </v-col>
          </v-row>
          <v-row class="pl-8 pr-8">
            <v-col
              :key="country"
              class="ma-0 pa-0"
              cols="6"
              v-for="country in project.countries"
            >
              <div class="d-flex">
                <v-btn
                  :aria-label="'Remove country ' + country"
                  icon
                  @click="removeCountry(country)"
                  v-if="editMode"
                >
                  <v-icon class="mr-2" color="error">mdi-close-circle</v-icon>
                </v-btn>
                <project-country flag-size="20px" text-size="18px" :country="[country]" />
              </div>
            </v-col>
          </v-row>
          <v-row justify="center" align="center" v-if="editMode">
            <v-col cols="8" class="mt-3">
              <v-select
                v-model="newCountry"
                :items="countries"
                item-text="name"
                label="Add country"
                style="height: 50px"
                outlined
                dense
                clearable
                prepend-inner-icon="mdi-map-marker"
                required
              >
                <template #item="slotProps">
                  <v-icon small class="mr-2" v-if="slotProps.item.flag == 'xx'">
                    mdi-earth
                  </v-icon>
                  <flag class="mr-2" :iso="slotProps.item.flag" v-else />
                  {{ slotProps.item.name }}
                </template>

                <template #selection="{ item, _ }">
                  <v-icon small class="mr-2" v-if="item.flag == 'xx'">mdi-earth</v-icon>

                  <flag class="mr-2" :iso="item.flag" v-else />
                  {{ item.name }}
                </template>
              </v-select>
            </v-col>
            <v-col cols="2" class="d-flex align-center justify-center">
              <v-btn
                :aria-label="'Add country ' + newCountry"
                icon
                @click="addCountry(newCountry)"
              >
                <v-icon large color="success">mdi-plus-circle</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <!-- List of companies -->
          <v-row class="mt-5">
            <v-col cols="12">
              <h3>Companies</h3>
            </v-col>
          </v-row>
          <v-row class="pl-8 pr-8">
            <v-col
              :key="c + i"
              cols="12"
              class="pa-0 ma-0"
              v-for="(c, i) in companyUrlList"
            >
              <div class="d-flex align-center">
                <v-btn
                  icon
                  :aria-label="'Remove company ' + companyNameList[i]"
                  @click="removeCompany(i)"
                  v-if="editMode"
                >
                  <v-icon class="mr-2" color="error">mdi-close-circle</v-icon>
                </v-btn>
                <v-icon class="mr-2" alt="Project company icon">
                  mdi-office-building
                </v-icon>
                <!-- prettier-ignore -->
                <a target="_blank" :href="'//' + c" v-if="c">{{ companyNameList[i] || c }}</a>
                <span v-else>{{ companyNameList[i] || c }}</span>
              </div>
            </v-col>
          </v-row>
          <v-row justify="center" align="center" v-if="editMode">
            <v-col cols="8" class="mt-3">
              <v-text-field
                v-model="newCompanyName"
                label="New company name"
                outlined
                style="height: 60px"
              ></v-text-field>
              <v-text-field
                v-model="newCompanyUrl"
                label="New company url"
                outlined
                class="mt-2"
                style="height: 60px"
              ></v-text-field>
            </v-col>
            <v-col cols="2" class="d-flex align-center justify-center">
              <v-btn
                :aria-label="'Add company ' + newCompanyName"
                icon
                @click="addCompany(newCompanyName, newCompanyUrl)"
              >
                <v-icon large color="success">mdi-plus-circle</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row class="mt-5" v-if="editMode || project.geolocation">
            <v-col cols="12">
              <h3>Location</h3>
            </v-col>
            <v-col cols="12" class="pa-0 ma-0 pl-8 pb-4" v-if="editMode">
              <v-icon class="mr-1">mdi-help-circle-outline</v-icon>
              <span v-if="project.geolocation">
                You can drag the marker to choose a new location.
              </span>
              <span v-else>Click anywhere in the map to add a location.</span>
            </v-col>
            <v-col cols="12" v-if="editMode">
              <vue-google-autocomplete
                id="map"
                class="vue-map"
                classname="form-control"
                :types="['geocode', 'establishment']"
                placeholder="Or you can type a place here"
                @placechanged="getAddressData"
              ></vue-google-autocomplete>
            </v-col>
            <v-col cols="12">
              <project-map
                :geolocation.sync="project.geolocation"
                :edit-mode="editMode"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import logo from '@bigml/bmlapp/src/assets/img/logo/bigml_version_light_ml.png'
import { formatDateTime } from '@bigml/bmlapp/src/core/helpers/datetime'
import VueGoogleAutocomplete from 'vue-google-autocomplete'
import { mapActions, mapGetters, mapState } from 'vuex'

import countries from '@/core/config/countries.js'
import ProjectCategory from '@/views/Project/ProjectCategory.vue'
import ProjectCountry from '@/views/Project/ProjectCountry.vue'
import ProjectMap from '@/views/Project/ProjectMap.vue'

export default {
  name: 'ProjectDetail',
  components: {
    ProjectCategory,
    ProjectCountry,
    ProjectMap,
    VueGoogleAutocomplete
  },
  props: {
    id: {
      type: String,
      required: true
    },
    edit: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: true,
      project: {},
      bigmlLogo: logo,
      validCategories: ['TELEWORK', 'ELEARNING', 'TELECARE', 'OTHER', 'UNKNOWN'],
      newCountry: '',
      newCompanyName: '',
      newCompanyUrl: '',
      newLogo: null,
      shownLogo: null,
      countries: countries,
      editMode: false,
      logoRules: [
        value =>
          !value || value.size < 3000000 || 'Logo image size should be less than 3 MB!'
      ]
    }
  },
  computed: {
    ...mapGetters('auth', ['isUserLoggedIn']),
    ...mapGetters('project', ['resources']),
    ...mapState('project', ['loadingCreateOrUpdate']),
    category() {
      return this.project.labels && this.project.labels[0]
    },
    publish() {
      return this.project.review ? this.project.review.review_status : 'PENDING'
    },
    companyUrlList() {
      return this.project.company_url.split('|')
    },
    companyNameList() {
      return this.project.company_name.split('|')
    },
    source() {
      let sid_source = this.project.source_id.split('@')
      let output = this.project.source
      if (sid_source.length > 1 && sid_source[0] != 'loaded') {
        output = output + ' + ' + sid_source[0]
      }
      return output
    },
    createdAtFormat() {
      return formatDateTime(this.project.created_at, {
        formatStr: 'yyyy-MM-dd'
      })
    },
    updatedAtFormat() {
      return formatDateTime(this.project.created_at, {
        formatStr: 'yyyy-MM-dd   hh:mm'
      })
    }
  },
  async created() {
    this.editMode = this.isUserLoggedIn ? this.edit : false
    this.loading = true
    await this.get(this.id)
    this.project = { ...this.resources[this.id] }
    this.previewImg()
    this.loading = false
  },
  methods: {
    ...mapActions('project', ['get', 'update', 'setLoadingCreateOrUpdate']),
    getAddressData: function (data, _) {
      this.project.geolocation = data.latitude + ',' + data.longitude
    },
    async save() {
      let toCreate = { ...this.project }
      toCreate['logo'] = this.newLogo
      try {
        await this.update({ ...toCreate, bmlMultipart: true })
        await this.update({
          id: this.project.id,
          labels: this.project.labels
        })
        await this.update({
          id: this.project.id,
          countries: this.project.countries
        })
      } catch (error) {
        this.setLoadingCreateOrUpdate(false)
      }
      this.project = this.resources[this.id]
      this.editMode = false
    },
    clickSave() {
      if (this.editMode) {
        this.save()
      } else {
        this.editMode = true
      }
    },

    previewImg() {
      if (this.newLogo) {
        this.shownLogo = URL.createObjectURL(this.newLogo)
      } else {
        this.shownLogo = this.getLogoImage(this.project.logo || '')
      }
    },
    removeCountry(country) {
      this.project.countries = this.project.countries.filter(item => item != country)
    },
    removeCompany(pos) {
      let names = [...this.companyNameList]
      names.splice(pos, 1)
      this.project.company_name = names.join('|')
      let urls = [...this.companyUrlList]
      urls.splice(pos, 1)
      this.project.company_url = urls.join('|')
    },
    addCountry(country) {
      if (country && !this.project.countries.includes(country)) {
        this.project.countries.push(country)
      }
      this.newCountry = ''
    },
    addCompany(name, url) {
      if (name || url) {
        this.project.company_name = this.project.company_name + '|' + name
        this.project.company_url = this.project.company_url + '|' + url
        this.newCompanyName = ''
        this.newCompanyUrl = ''
      }
    },
    getLogoImage(logo) {
      if (logo == '') {
        if (this.category == 'ELEARNING') {
          const num = this.id % 16
          logo = 'categories/ELEARNING/' + num + '.jpg'
        } else if (this.category == 'TELECARE') {
          const num = this.id % 15
          logo = 'categories/TELECARE/' + num + '.jpg'
        } else if (this.category == 'TELEWORK') {
          const num = this.id % 19
          logo = 'categories/TELEWORK/' + num + '.jpg'
        } else {
          logo = 'logo.png'
        }
      }
      if (logo.startsWith('http')) {
        return logo
      } else {
        return require(`@/assets/img/${logo}`)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.vue-map {
  width: 100%;
  padding: 10px;
  border: 1px solid #666;
}
</style>
<style lang="scss">
.img-container {
  background-color: #0009;
}

.project-detail-title {
  color: $color-pseudo-white;
  font-size: 25px;
}
</style>
<style lang="scss">
/* stylelint-disable */
.project-detail-title * {
  font-size: 25px;
  font-weight: 700 !important;
}
/* stylelint-enable */
</style>
