<template>
  <v-container class="new-form pt-10 pb-10" fill-height>
    <v-row justify="center" class="mb-2">
      <h2 style="font-size: 30px">Project information</h2>
    </v-row>
    <v-form ref="form" v-model="valid">
      <v-container>
        <v-row no-gutters>
          <v-col cols="12">
            <v-text-field
              ref="fieldname"
              v-model="project.name"
              outlined
              :rules="rules.nameRules"
              :counter="60"
              label="Project name*"
              required
              prepend-inner-icon="mdi-folder-text"
              dense
              class="mb-2"
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-text-field
              v-model="project.email"
              outlined
              :rules="rules.emailRules"
              :counter="200"
              label="Email*"
              required
              prepend-inner-icon="mdi-at"
              dense
              class="mb-2"
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-select
              v-model="project.categories"
              :items="validCategories"
              :rules="rules.categoryRules"
              label="Project Category*"
              multiple
              outlined
              required
              prepend-inner-icon="mdi-shape"
            ></v-select>
          </v-col>

          <v-col cols="12">
            <v-text-field
              v-model="project.url"
              dense
              outlined
              :rules="rules.urlRules"
              :counter="200"
              label="Project URL"
              prepend-inner-icon="mdi-link-variant"
              class="mb-2"
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-select
              v-model="project.country"
              :items="countries"
              item-text="name"
              fromform
              label="Country*"
              outlined
              dense
              prepend-inner-icon="mdi-map-marker"
              required
              :rules="rules.countryRules"
            >
              <template #item="slotProps">
                <v-icon class="mr-1" v-if="slotProps.item.flag == 'xx'">mdi-earth</v-icon>
                <flag class="mr-2" :iso="slotProps.item.flag" v-else />
                {{ slotProps.item.name }}
              </template>

              <template #selection="{ item, _ }">
                <v-icon class="mr-1" v-if="item.flag == 'xx'">mdi-earth</v-icon>
                <flag class="mr-2" :iso="item.flag" v-else />
                {{ item.name }}
              </template>
            </v-select>
          </v-col>

          <v-col cols="12">
            <v-file-input
              v-model="logo"
              label="Project logo"
              accept="image/*"
              truncate-length="90"
              show-size
              prepend-inner-icon="mdi-camera"
              prepend-icon=""
              outlined
              :rules="rules.logoRules"
            ></v-file-input>
          </v-col>

          <v-col cols="12">
            <v-textarea
              v-model="project.description"
              outlined
              auto-grow
              rows="3"
              :rules="rules.descriptionRules"
              :counter="3500"
              label="Project Description*"
              required
              prepend-inner-icon="mdi-card-text"
            ></v-textarea>
          </v-col>

          <v-col cols="12">
            <v-text-field
              v-model="project.companyURL"
              outlined
              :rules="rules.companyUrlRules"
              :counter="200"
              label="Company URL"
              prepend-inner-icon="mdi-search-web"
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-checkbox v-model="terms" :rules="rules.termRules">
              <template #label>
                I have read and accept the
                <a
                  class="ml-2 mr-1 terms-link"
                  href="#"
                  @click="showTerms = true"
                  @click.stop
                >
                  Terms & Conditions
                </a>
                *
              </template>
            </v-checkbox>
          </v-col>
          <v-col cols="12" class="pa-5" v-if="!terms">
            <v-icon color="orange">mdi-alert</v-icon>
            You must accept terms before sending a project
          </v-col>
          <v-col cols="12" class="pt-2">
            <v-btn
              :loading="loadingCreateOrUpdate"
              large
              block
              :color="valid ? 'primary' : '#F2B8B9'"
              @click="send"
            >
              Send project
              {{ valid ? '' : '(Not completed)' }}
            </v-btn>
            <v-col class="mt-5" cols="12" v-show="messageShow">
              <v-alert :type="messageType">
                <span ref="uploadalert" tabindex="0">{{ messageText }}</span>
                <v-btn text @click="closeMessage">
                  <v-icon>mdi-close</v-icon>
                  Close
                </v-btn>
              </v-alert>
            </v-col>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
    <v-dialog v-model="showTerms" width="800">
      <v-card>
        <v-card-title class="text-h5">Terms & Conditions</v-card-title>

        <v-card-text>
          <p>
            Fundación ONCE para la Cooperación e Inclusión Social de Personas con
            Discapacidad is responsible for the processing of the personal data you have
            filled in the form with the purpose of being included in the mapping tool of
            ACCESSIBILITECH.
          </p>

          <p>
            The legal basis that legitimizes the treatment is the need to satisfy the
            legitimate interests pursued by the entity responsible for the treatment. Your
            personal data will not be transferred to third parties nor are international
            transfers of them planned.
          </p>

          <p>
            We inform you that, you may exercise your rights of access, rectification,
            deletion, by sending an email attaching a scanned copy of your ID
            protecciondatos@fundaciononce.es
          </p>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="showTerms = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import countries from '@/core/config/countries.js'

const defaultProject = {
  name: '',
  url: '',
  email: '',
  categories: [],
  description: '',
  country: '',
  companyURL: ''
}

export default {
  name: 'NewProjectForm',
  data() {
    return {
      messageShow: false,
      messageType: 'success',
      messageText: '',
      showTerms: false,
      valid: false,
      terms: false,
      logo: null,
      project: { ...defaultProject },
      // Form options
      validCategories: ['Telework', 'eLearning', 'Telecare', 'Other'],
      countries: countries,
      // Form validation
      rules: {
        nameRules: [
          v => !!v || 'Name is required',
          v => (v && v.length <= 60) || 'Name must be less than 60 characters'
        ],
        emailRules: [
          v => !!v || 'E-mail is required',
          v => /^(.*?)@(.*?)$/.test(v) || 'E-mail must be valid'
        ],
        categoryRules: [v => (v && v.length > 0) || 'At least one category required'],
        countryRules: [v => !!v || 'Country is required'],
        urlRules: [v => this.isURL(v) || 'URL is not valid'],
        descriptionRules: [
          v => !!v || 'Description is required',
          v => !v || v.length <= 3500 || 'Description must be less than 3500 characters'
        ],
        companyUrlRules: [v => this.isURL(v) || 'URL is not valid'],
        logoRules: [
          value =>
            !value || value.size < 3000000 || 'Logo image size should be less than 3 MB!'
        ],
        termRules: [v => v || 'You must read and accept terms']
      }
    }
  },
  computed: {
    ...mapState('project', ['loadingCreateOrUpdate'])
  },
  methods: {
    ...mapActions('project', ['create', 'setLoadingCreateOrUpdate']),
    newProject(info) {
      this.messageShow = true
      this.messageType = 'success'
      this.messageText = `Project ${info.name} sent correctly. Accessibilitech team will review it.`
      this.$refs.uploadalert.focus()
      setTimeout(() => {
        this.$refs.uploadalert.focus()
      }, 100)
    },
    newProjectError(info) {
      this.messageShow = true
      this.messageType = 'error'
      this.messageText = `There was an error while uploading project: ${info}`
    },
    closeMessage() {
      this.messageShow = false
      this.$refs.projectForm.resetFocus()
    },
    resetFocus() {
      this.$refs.fieldname.focus()
    },
    fixURL(url) {
      if (url.startsWith('http')) {
        return url
      }
      return 'https://' + url
    },
    isURL(str) {
      if (!str || str.length == 0) {
        return true
      }
      var pattern = new RegExp(
        '^(https?:\\/\\/)?' + // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
          '(\\#[-a-z\\d_]*)?$',
        'i'
      ) // fragment locator
      return pattern.test(this.fixURL(str))
    },
    async send() {
      if (this.valid) {
        let toCreate = { ...this.project }
        toCreate['contact_email'] = toCreate.email
        delete toCreate.email
        toCreate['labels'] = toCreate.categories.map(c => c.toUpperCase())
        delete toCreate.categories

        if (toCreate.url.length == 0) {
          delete toCreate.url
        } else {
          toCreate.url = this.fixURL(toCreate.url)
        }
        if (toCreate.companyURL.length > 0) {
          toCreate['company_url'] = this.fixURL(toCreate.companyURL)
        }
        delete toCreate.companyURL
        if (toCreate.country.length > 0) {
          toCreate['countries'] = [toCreate.country]
        }
        delete toCreate.country
        toCreate['logo'] = this.logo
        try {
          await this.create({ ...toCreate, bmlMultipart: true })
          this.newProject(toCreate)

          this.$refs.form.reset()
          this.project = { ...defaultProject }
        } catch (error) {
          this.newProjectError(error.response.statusText)
          this.setLoadingCreateOrUpdate(false)
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.new-form {
  padding-right: 20px;
  padding-left: 20px;
}

.v-input--selection-controls {
  margin-top: 0;
  padding-top: 0;
}
</style>
