<template>
  <v-chip
    class="project-category-label"
    label
    outlined
    disabled
    color="primary"
    text-color="primary"
    :style="'height: ' + height + ' !important'"
  >
    <v-avatar left>
      <v-img :src="require(`@/assets/img/${categories[category].img}`)" contain></v-img>
    </v-avatar>
    {{ categories[category].name }}
  </v-chip>
</template>
<script>
export default {
  name: 'ProjectCategory',
  props: {
    category: {
      type: String,
      required: true
    },
    height: {
      type: String,
      default: '28px'
    }
  },
  data() {
    return {
      categories: {
        ELEARNING: {
          name: 'e-Learning',
          img: 'elearning.png'
        },
        TELEWORK: {
          name: 'Telework',
          img: 'telework.png'
        },
        TELECARE: {
          name: 'Telecare',
          img: 'telecare.png'
        },
        OTHER: {
          name: 'Other',
          img: 'logo.png'
        },
        UNKNOWN: {
          name: 'Unknown category',
          img: 'logo.png'
        }
      }
    }
  },
  methods: {}
}
</script>
<style lang="scss" scoped>
.project-category-label {
  width: 100%;
}
</style>
<style>
.project-category-label {
  background-color: transparent !important;
  opacity: 1 !important;
}
</style>
