<template>
  <v-container class="new-info pb-10">
    <v-container>
      <v-row justify="center">
        <v-img
          alt="Accessibilitech logo"
          max-width="400"
          src="@/assets/img/logo.png"
        ></v-img>
      </v-row>
      <v-row justify="center" no-gutters class="mt-15">
        <v-col cols="10">
          <p class="main-text" v-if="lang == 'es'">
            ¿Quieres incluir tu solución accesible de teletrabajo, eLearning o
            teleasistencia en
            <span class="font-weight-black">mapa</span>
            ?
          </p>
          <p class="main-text" v-else>
            Do you have a solution to improve accessibility in e-learning, telework or
            telecare services you would like to include in our
            <span class="font-weight-black">Mapping Tool</span>
            ?
          </p>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="10">
          <p v-if="lang == 'es'">
            Este
            <span class="font-weight-black">mapa</span>
            es un sistema que recoge soluciones tecnológicas accesibles e inclusivas, en
            cualquier nivel de madurez, que mejoren la calidad de vida de las personas con
            discapacidad. Ofrecerá una fuente de información y referencia sobre
            tecnologías accesibles para personas con discapacidad. Tanto si eres un grupo
            de investigación de una universidad, una empresa, una entidad sin ánimo de
            lucro o incluso un particular, no dudes en enviarnos la información sobre tu
            producto, servicio, herramienta, proyecto o iniciativa.
          </p>
          <p v-else>
            This
            <span class="font-weight-black">mapping tool</span>
            is a system that gathers accessible and inclusive technological solutions, at
            all levels of maturity, with potential to improve the quality of life of
            people with disabilities. It will offer a source of information on accessible
            technologies for persons with disabilities. Whether you are a research group
            from a university, a company, a non-profit organisation or even an individual,
            do not hesitate to send us information on your product, service, tool, project
            or initiative.
          </p>
        </v-col>
        <v-col cols="10">
          <v-btn
            outlined
            color="primary"
            block
            :dark="true"
            href="https://accessibilitech.accessibilitas.es"
          >
            <v-icon>mdi-exit-to-app</v-icon>
            <span class="ml-1" v-if="lang == 'es'">
              Ir al sitio web de Accessibilitech
            </span>
            <span class="ml-1" v-else>Go to Accessibilitech website</span>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-container>
      <div class="logo-divider"></div>
      <v-row dense class="pt-12 pl-15 pr-15" justify="center" align="center">
        <v-col cols="2" justify="center" align="center">
          <a href="https://www.fundaciononce.es/es/quienes-somos/presentacion">
            <v-img
              alt="Fundación ONCE logo"
              contain
              max-width="140"
              src="@/assets/img/fo.png"
            />
          </a>
        </v-col>

        <v-col cols="4" justify="center" align="center">
          <a href="https://www.insertaempleo.es/innovacion/asociacion-inserta">
            <v-img
              alt="Inserta Innovación logo"
              contain
              max-width="270"
              src="@/assets/img/inserta.png"
            />
          </a>
        </v-col>
        <v-col cols="2" justify="center" align="center">
          <a href="https://www.digitaleurope.org/about-us/">
            <v-img
              alt="Digital Europe logo"
              contain
              max-width="150"
              src="@/assets/img/de.png"
            />
          </a>
        </v-col>
        <v-col cols="2" justify="center" align="center">
          <a href="https://www.easpd.eu/">
            <v-img
              alt="EASPD logo"
              contain
              max-width="100"
              src="@/assets/img/easpd.svg"
            />
          </a>
        </v-col>
      </v-row>

      <v-row dense class="mt-0 pl-15 pr-15" justify="center" align="center">
        <v-col cols="2" justify="center" align="center">
          <a href="https://www.edf-feph.org/about-us/about-us-2/">
            <v-img
              alt="European Disability Forum logo"
              contain
              max-width="70"
              src="@/assets/img/edf.png"
            />
          </a>
        </v-col>
        <v-col cols="2" justify="center" align="center">
          <a href="https://philea.eu/">
            <v-img
              alt="Philanthropy Europe Association"
              contain
              max-width="100"
              src="@/assets/img/philea.jpg"
            />
          </a>
        </v-col>
        <v-col cols="2" justify="center" align="center">
          <a href="https://www.socialeconomy.eu.org/about/">
            <v-img
              alt="Social Economy Europe logo"
              contain
              max-width="110"
              src="@/assets/img/see.png"
            />
          </a>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
export default {
  name: 'NewProjectInfo',
  props: {
    lang: {
      type: String,
      default: 'es'
    }
  }
}
</script>

<style lang="scss" scoped>
.new-info {
  margin-bottom: 2%;
  padding-top: 7%;
}

.new-info .row .main-text {
  color: $color-atech-primary;
  font-weight: 600;
  font-size: 20px;
}

.new-info .row p {
  color: $color-atech-primary;
  font-size: 17px;
}

.new-info .row p a {
  color: $color-atech-primary;
  font-size: 17px;
}

.new-info .row .subheader-text {
  font-weight: 700; /* bold */
  font-size: 20px;
}

.logo-divider {
  width: 100%;
  height: 1px;
  margin-top: 4%;
  background-color: #ccc;
}
</style>
