var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('bml-vuex-resource-iterator',{directives:[{name:"show",rawName:"v-show",value:(_vm.filters.name != 'sillywayofavoidingshowinganyproject'),expression:"filters.name != 'sillywayofavoidingshowinganyproject'"}],attrs:{"vuex-namespace":"project","hide-default-footer":"","cols-props":{ cols: 12, md: 6, xl: 4 },"external-filters":_vm.filters},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var loading = ref.loading;
return [(loading)?_c('v-progress-linear',{staticClass:"mb-5",attrs:{"color":"primary","indeterminate":""}}):_vm._e()]}},{key:"loading",fn:function(){return [_c('div',{staticStyle:{"height":"100px","text-align":"center"}},[_c('p',[_vm._v("Loading. Please wait...")])])]},proxy:true},{key:"item",fn:function(ref){
var item = ref.item;
return [(!_vm.prLoading && _vm.isUserLoggedIn)?_c('intranet-project-card',_vm._g({attrs:{"project":item}},_vm.$listeners)):(!_vm.prLoading && !_vm.isUserLoggedIn)?_c('project-card',{attrs:{"project":item}}):_vm._e()]}},{key:"footer",fn:function(ref){
var pagination = ref.pagination;
var totalPages = ref.totalPages;
return [_c('div',{staticClass:"text-center mt-8 mb-8"},[(totalPages > 1)?_c('v-pagination',{attrs:{"length":totalPages,"total-visible":7,"circle":""},model:{value:(pagination.page),callback:function ($$v) {_vm.$set(pagination, "page", $$v)},expression:"pagination.page"}}):_vm._e()],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }